<template >
  <div class="trialApplication">
    <div class="logButton clearFix">
      <div class="export">
        <!-- <el-button class="themeButton" @click="showDialog">添加虚拟实验</el-button> -->
        <el-select
          filterable
          remote
          clearable
          v-model="state"
          placeholder="请选择状态"
          @change="handleSearchColleague"
          class
        >
          <el-option v-for="item in stateList" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="search">
        <!-- 搜索题目 -->
        <div class="searchContent">
          <el-input
            placeholder="请输入请输入教师名称"
            v-model="input"
            clearable
            @clear="handleSearchColleague"
            class="input-with-select"
          ></el-input>
        </div>
        <el-button class="confirmButton" @click="handleSearchColleague">搜索</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-card>
      <el-table ref="multipleTable" :data="schoolList" tooltip-effect="dark" style="width: 100%">
        <!-- 序号 -->
        <el-table-column type="index" :index="indexMethod" label="序号" width="100"></el-table-column>
        <!--关键词  -->
        <el-table-column prop="addtime" label="申请时间"></el-table-column>
        <!--姓名  -->
        <el-table-column prop="username" label="姓名"></el-table-column>
        <!--身份  -->
        <el-table-column label="身份">
          <template slot-scope="scope">
            <div v-if="scope.row.type=='0'">学生</div>
            <div v-else-if="scope.row.type=='1'">老师</div>
            <div v-else>管理员</div>
          </template>
        </el-table-column>
        <!--学校  -->
        <el-table-column prop="schoolName" label="学校"></el-table-column>
        <!--电话  -->
        <el-table-column prop="tel" label="电话"></el-table-column>
        <!--step  -->
        <!-- <el-table-column prop="step" label="备注"></el-table-column> -->
        <!--项目数  -->
        <el-table-column prop="expNumber" label="项目数"></el-table-column>
        <!--step  -->
        <!-- <el-table-column prop="step" label="区域负责"></el-table-column> -->
        <!--step  -->
        <el-table-column prop="step" label="状态">
          <template slot-scope="scope">
            <div v-if="scope.row.state=='1'">申请中</div>
            <div v-else-if="scope.row.state=='2'" type="success">已批准</div>
            <div v-else type="danger">已拒绝</div>
          </template>
        </el-table-column>

        <!-- 操作-->
        <el-table-column min-width="120" label="操作">
          <template slot-scope="scope">
            <!-- <div class="operatorItem floatLeft" @click="addSchool(scope.row)">添加</div> -->
            <div
              v-show="scope.row.state=='1'"
              class="operatorItem floatLeft"
              @click="updatePic(scope.row)"
            >批准</div>
            <div
              v-show="scope.row.state=='1'"
              class="operatorItem floatLeft"
              @click="deletePic(scope.row)"
            >拒绝</div>
            <div
              v-show="scope.row.state!=='1'"
              class="operatorItem floatLeft"
              @click="checkPic(scope.row)"
            >查看</div>
            <!-- <div class="operatorItem floatLeft">申请中</div> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        background
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="schoolListTotal"
      ></el-pagination>
    </el-card>
    <el-dialog :title="infoTitle" :visible.sync="infoVisible" width="60%">
      <span class="dialog-span expTable">
        <el-table :data="expList" tooltip-effect="dark" style="width: 100%">
          <!-- 序号 -->
          <el-table-column type="index" label="序号" width="100"></el-table-column>
          <!--实验名称  -->
          <el-table-column prop="name" min-width="50" label="实验名称"></el-table-column>
          <!--开发者  -->
          <el-table-column prop="username" min-width="20" label="开发者"></el-table-column>
          <!--学校  -->
          <el-table-column prop="schoolName" min-width="30" label="学校"></el-table-column>
          <!--描述  -->
          <el-table-column label="描述">
            <template slot-scope="scope">
              <span v-html="scope.row.des"></span>
            </template>
          </el-table-column>
        </el-table>
        <div class="applicationStatus" v-show="sign == 'check'">
          <el-radio disabled v-model="status" label="0">本人试用</el-radio>
          <el-radio disabled v-model="status" label="1">课程应用</el-radio>
        </div>
        <div class="applicationStatus" v-show="sign !== 'check'">
          <el-radio disabled v-model="radio" label="0">不创建班级</el-radio>
          <el-radio disabled v-model="radio" label="1">创建班级</el-radio>
          <div v-if="radio == '1'">
            <span class="uploadtipButton" @click="downloadImportTemplate()">点击下载模板</span>
            <div>
              <el-upload
                class="upload-demo"
                :action="uploadUrl"
                :on-success="markHandleAvatarSuccess"
                :limit="1"
                :on-exceed="handleExceed"
                :before-upload="markHandleBeforeUpload"
                :file-list="fileList"
              >
                <el-button class="uploadButton">点击上传文件</el-button>
                <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件，且不超过5MB</div>
              </el-upload>
            </div>
          </div>
        </div>

      </span>
      <span slot="footer" class="dialog-footer" v-show="sign !== 'check'">
        <el-button class="cancelButton" @click="confirmUpdate(0)">拒绝申请</el-button>
        <el-button class="confirmButton" @click="confirmUpdate(2)">确认通过</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 引入试题列表js
import trialApplication from "@/assets/js/manager/trialApplication/trialApplication.js";
export default {
  ...trialApplication
};
</script>

<style>
.trialApplication .confirmButton {
  padding: 0 0.7vw !important;
  height: 2.0833vw !important;
  background-color: #0d5570 !important;
  color: #ffffff !important;
  border-radius: 0.2083vw !important;
  font: normal normal normal 0.8333vw Source Han Sans CN !important;
}
.trialApplication .el-input__inner {
  font-size: 0.8333vw;
  height: 2.0833vw;
}
.trialApplication .search .el-input,
.trialApplication .search .el-select {
  width: 15vw !important;
}
.trialApplication .cancelButton {
  padding: 0 0.7vw !important;
  height: 2.0833vw !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 0.0521vw solid #cccccc !important;
  border-radius: 0.2083vw !important;
  font: normal normal normal 0.8333vw Source Han Sans CN !important;
}
.trialApplication
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #0d5570;
  color: #fff;
}
.trialApplication .el-table__body-wrapper {
  height: 67vh;
  overflow: scroll;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: scroll;
}
.trialApplication .expTable .el-table__body-wrapper {
  height: 40vh;
  overflow: scroll;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: scroll;
}
.trialApplication .el-dialog {
  /* padding: 1.5625vw 1.0417vw;
    padding-top: 0.2604vw;
    color: #606266;
    font-size: 0.7292vw;
    word-break: break-all;
    padding-bottom: 1.5625vw; */
  border-radius: 0.625vw !important;
}
.trialApplication .el-radio__input.is-checked .el-radio__inner {
  border-color: #0d5570;
  background: #0d5570;
}
.trialApplication .el-radio__input.is-checked + .el-radio__label {
  color: #0d5570;
}
.trialApplication .el-table__body-wrapper::-webkit-scrollbar {
  display: none;
}
.trialApplication .el-upload {
  /* border: 1px dashed #d9d9d9 !important; */
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.trialApplication .avatar-uploader {
  /* text-align:center; */
}
.trialApplication .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.trialApplication .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.trialApplication .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.trialApplication .grantBox .el-table__body-wrapper {
  height: 25vw;
  overflow: scroll;
  position: relative;
}
.trialApplication .grantBox .el-checkbox__input.is-checked .el-checkbox__inner,
.trialApplication .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0d5570;
  border-color: #0d5570;
}
.trialApplication .grantBox .el-table__body-wrapper::-webkit-scrollbar {
  display: none;
}
</style>
<style lang="scss" scoped>
// 引入试题列表组件css
@import "@/assets/css/manager/trialApplication/trialApplication.scss";
</style>